import { useI18n } from "vue-i18n";

export const useCardBottomList = (
  locations: string[],
  shortList: boolean = false,
) => {
  const length = locations.length;
  const { t } = useI18n({
    useScope: "global",
    messages: {
      cs: {
        more: "0 dalších | 1 další | {count} další | {count} dalších",
      },
      en: {
        more: "0 others | 1 other | {count} more | {count} more",
      },
    },
  });

  if (shortList && length > 2) {
    return `${locations[0]} + ${t("more", length - 1)}`;
  } else
    return locations
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(", ");
};
