<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
  >
    <g clip-path="url(#clip0_16389_162932)">
      <path
        d="M14.5 6.68945H1.5V17.3795L7.98 14.0595L14.5 17.3795V6.68945Z"
        fill="currentColor"
      />
      <path
        d="M15 17.6895L7.97 14.1895L1 17.6895V1.68945H15V17.6895Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M14.5 1.68945H1.5V3.68945H14.5V1.68945Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M14.5 7.68945H1.5V8.68945H14.5V7.68945Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M4 14.6895L4 1.68945L1 1.68945L1 14.6895H4Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M15 14.6895V1.68945L12 1.68945V14.6895H15Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_16389_162932">
        <rect
          width="16"
          height="18.62"
          fill="white"
          transform="translate(0 0.689453)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
