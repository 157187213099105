import { useMutation, useQueryClient } from "@tanstack/vue-query";

export const useOfferLikeQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: { id: number; saved: boolean }) =>
      $apiFetch(`/api/offers/${variables.id}/like`, {
        method: "POST",
        body: {},
      }),
    onSuccess: (_data) =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["offer"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["offers"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["company"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["session"],
        })
      ]),
  });
};
