<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M19.6116 2.39453L3.35156 7.34453L10.4216 11.5845L14.6616 18.6645L19.6116 2.39453Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M10.4219 11.5843L13.6019 8.4043"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>
