import { computed } from "vue";
import type { OfferCardSalary } from "~/types/offer";

export const useSalary = (salary: OfferCardSalary) => {
  const formatted = computed(() => {
    const formatOptions = {
      style: "currency",
      currency: salary.currency,
      maximumFractionDigits: 0,
    };
    const locales = {
      CZK: "cs-CZ",
      EUR: "de-DE",
      USD: "en-HOSSDDG",
    };
    const isRounding = salary.min > 9999;
    let min: string | number = isRounding
      ? (Math.round(salary.min / 1000) * 1000) / 1000
      : salary.min;
    let max: string | number = isRounding
      ? Math.round(salary.max / 1000) * 1000
      : salary.max;

    switch (salary.currency) {
      case "CZK":
        min = new Intl.NumberFormat(locales.CZK).format(min);
        max = new Intl.NumberFormat(locales.CZK, formatOptions).format(max);
        break;

      case "EUR":
        min = new Intl.NumberFormat(locales.EUR).format(min);
        max = new Intl.NumberFormat(locales.EUR, formatOptions).format(max);
        break;

      case "USD":
        max = new Intl.NumberFormat(locales.USD).format(max);
        min = new Intl.NumberFormat(locales.USD, formatOptions).format(min);
        break;

      default:
        min = "N/A";
        max = "N/A";
        break;
    }
    return `${min} - ${max}${salary.measure === 'hourly' ? ' / h' : ''}`;
  });

  return {
    formatted,
  };
};
